//React
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Styling
import "./styles/nav.scss";

//Images
import mainLogo from "../images/nitrocam_logo.webp";

//MUI
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Divider } from "@mui/material";
import { Padding } from "@mui/icons-material";
//Inpage Styling
const linkStyle = {
    textDecoration: "none",
    color: "#151042",
};
const menuItemStyle = {
    fontWeight: 600,
    fontSize: "1.1rem",
    padding: "10px 50px",
};
//Interfaces
interface Props {
    setLoggedIn: any;
}

export default function Nav({ setLoggedIn }: Props) {
    const navigate = useNavigate();

    //MENU MUI START--------------------------------------------------
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //MENU MUI END----------------------------------------------------

    const handleLogout = () => {
        localStorage.clear();
        setLoggedIn(false);
        navigate("/login");
    };
    return (
        <nav>
            <img
                src={mainLogo}
                alt="NitroPos Logo"
                className="nav_nitropos_logo"
                onClick={() => navigate("/")}
            />

            <section className="nav_menu">
                <Button
                    id="menu_icon"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                >
                    {open ? (
                        <MenuOpenIcon fontSize="large" />
                    ) : (
                        <MenuIcon fontSize="large" />
                    )}
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    className="menu-items"
                >
                    <Link to="/" style={linkStyle}>
                        <MenuItem style={menuItemStyle} onClick={handleClose}>
                            Overview
                        </MenuItem>{" "}
                    </Link>
                    <Divider />{" "}
                    <Link to="/add-face" style={linkStyle}>
                        <MenuItem style={menuItemStyle} onClick={handleClose}>
                            Add Face
                        </MenuItem>{" "}
                    </Link>
                    <Divider />{" "}
                    <Link to="/find-face" style={linkStyle}>
                        <MenuItem style={menuItemStyle} onClick={handleClose}>
                            Find Face
                        </MenuItem>{" "}
                    </Link>
                    <Divider />{" "}
                    <Link to="/reporting" style={linkStyle}>
                        <MenuItem style={menuItemStyle} onClick={handleClose}>
                            Reporting
                        </MenuItem>{" "}
                    </Link>
                    <Divider />{" "}
                    <Link to="/admin" style={linkStyle}>
                        <MenuItem style={menuItemStyle} onClick={handleClose}>
                            Admin
                        </MenuItem>{" "}
                    </Link>
                    <Divider />{" "}
                    <Link to="/historic" style={linkStyle}>
                        <MenuItem style={menuItemStyle} onClick={handleClose}>
                            Historic
                        </MenuItem>{" "}
                    </Link>
                    <Divider />{" "}
                    <MenuItem style={menuItemStyle} onClick={handleLogout}>
                        Logout
                    </MenuItem>{" "}
                </Menu>
            </section>
        </nav>
    );
}
