import React, { useState, useEffect } from 'react';
import './MultiSelect.scss';

interface Option {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: Option[];
  placeholder?: string;
  onChange: (selected: Option[]) => void;
  value?:any
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, placeholder = 'Select options', onChange, value=null }) => {
  const [selected, setSelected] = useState<Option[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  
  useEffect(()=>{
    console.log('Multi select options', value)
    if(!value|| value.length==0) setSelected([])
  }, [value])
  const handleSelect = (option: Option) => {
    if (!selected.some((item) => item.value === option.value)) {
      const newSelected = [...selected, option];
      setSelected(newSelected);
      onChange(newSelected);
    }
    setIsOpen(false);
    setSearch('');
  };

  const removeChip = (value: string) => {
    const newSelected = selected.filter((item) => item.value !== value);
    setSelected(newSelected);
    onChange(newSelected);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="multi-select">
      <div className="select-box" onClick={() => setIsOpen(!isOpen)}>
        {selected.map((chip) => (
          <span key={chip.value} className="chip">
            {chip.label}
            <span className="close" onClick={(e) => { e.stopPropagation(); removeChip(chip.value); }}>
              ×
            </span>
          </span>
        ))}
        <input
          type="text"
          placeholder={selected.length ? '' : placeholder}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {isOpen && (
        <div className="dropdown">
          {filteredOptions.map((option) => (
            <div
              key={option.value}
              className={`option ${selected.some((item) => item.value === option.value) ? 'selected' : ''}`}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
