import { useState } from "react";
//Components
import RoomModal from "./RoomModal";
//MUI
import { Modal } from "@mui/material";

interface Props {
    room: any;
}

export default function Room({ room }: Props) {
    const [openRoomModal, setOpenRoomModal] = useState(false);
    return (
        <>
            <div className="room-card" onClick={() => setOpenRoomModal(true)}>
                <h2>{room?.room}</h2>
            </div>
            <Modal open={openRoomModal} onClose={() => setOpenRoomModal(false)}>
                <RoomModal room={room} setOpenRoomModal={setOpenRoomModal} />
            </Modal>
        </>
    );
}
