// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* GLOBAL SCROLL BAR STYLING START ======== */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffe169;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #151042;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
/* GLOBAL SCROLL BAR STYLING END ==========*/
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI;QACI,4CAA4C;IAChD;AACJ;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA,6CAA6C;AAC7C;IACI,WAAW;IACX,YAAY;AAChB;;AAEA,UAAU;AACV;IACI,mBAAmB;IACnB,4BAA4B;IAC5B,+BAA+B;AACnC;;AAEA,WAAW;AACX;IACI,mBAAmB;IACnB,4BAA4B;IAC5B,+BAA+B;AACnC;;AAEA,oBAAoB;AACpB;IACI,gBAAgB;AACpB;AACA,4CAA4C","sourcesContent":[".App {\n    text-align: center;\n}\n\n.App-logo {\n    height: 40vmin;\n    pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n        animation: App-logo-spin infinite 20s linear;\n    }\n}\n\n.App-header {\n    background-color: #282c34;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: white;\n}\n\n.App-link {\n    color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n\n/* GLOBAL SCROLL BAR STYLING START ======== */\n::-webkit-scrollbar {\n    width: 10px;\n    height: 10px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n    background: #ffe169;\n    border-top-right-radius: 5px;\n    border-bottom-right-radius: 5px;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n    background: #151042;\n    border-top-right-radius: 5px;\n    border-bottom-right-radius: 5px;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n    background: #555;\n}\n/* GLOBAL SCROLL BAR STYLING END ==========*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
