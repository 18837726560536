import { useState, useEffect } from "react";
import axios from "axios";
//Components
import Site from "./Site";

export default function OfflineDevices() {
    const url = process.env.REACT_APP_API_URL;
    const [offlineReportingData, setOfflineReportingData] = useState([]);

    useEffect(() => {
        fetchOfflineReportingData();
    }, []);

    const fetchOfflineReportingData = async () => {
        try {
            const _offlineReportingData: any = await axios.get(
                `${url}/room/reports/offline`
            );
            console.log(_offlineReportingData);
            setOfflineReportingData(_offlineReportingData.data);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <section className="offline-devices-report">
            {" "}
            {offlineReportingData.length > 0
                ? offlineReportingData.map((site: any, i: number) => {
                      return <Site key={i} site={site} />;
                  })
                : null}
        </section>
    );
}
