import { useState } from "react";
import moment from "moment";
//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

//MUI - Graphs
import { LineChart } from "@mui/x-charts/LineChart";

interface Props {
    room: any;
    setOpenRoomModal: any;
}

//TABS =======================================================
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
//TABS =======================================================

export default function RoomModal({ room, setOpenRoomModal }: Props) {
    //TABS=======================================
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    //TABS=========================================

    return (
        <Box className="connected-devices-room-modal">
            <h2 className="main_heading room-header">Room: {room.room}</h2>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {room?.devices?.length > 0
                        ? room?.devices.map((device: any, i: number) => {
                              return (
                                  <Tab
                                      label={
                                          device.deviceName
                                              ? device.deviceName
                                              : i + 1
                                      }
                                      {...a11yProps(i)}
                                  />
                              );
                          })
                        : null}
                </Tabs>
            </Box>
            {room?.devices?.length > 0
                ? room?.devices.map((device: any, i: number) => {
                      return (
                          <CustomTabPanel value={value} index={i}>
                              <section className="device-header-information">
                                  <div className="single-info">
                                      <h2>Device Name</h2>
                                      <p>
                                          {device.deviceName
                                              ? device.deviceName
                                              : "No Device Name"}
                                      </p>
                                  </div>
                                  <div className="single-info">
                                      <h2>Device ID / Socket ID</h2>
                                      <p>
                                          {device.id} / {device.socketId}
                                      </p>
                                  </div>
                                  <div className="single-info">
                                      <h2>Connected Date/Time</h2>
                                      <p>
                                          {device.connectedTime ? (
                                              moment(
                                                  device.connectedTime
                                              ).format("HH:mm - DD/MM/YY")
                                          ) : (
                                              <DoNotDisturbAltIcon />
                                          )}
                                      </p>
                                  </div>{" "}
                                  <div className="single-info">
                                      <h2>Disconnected Date/Time</h2>
                                      <p>
                                          {device.disconnectedTime ? (
                                              moment(
                                                  device.disconnectedTime
                                              ).format("HH:mm- DD/MM/YY")
                                          ) : (
                                              <DoNotDisturbAltIcon />
                                              
                                          )}
                                      </p>
                                  </div>{" "}
                              </section>

                              {device?.logs?.length > 0 ? (
                                  <>
                                      <h2 className="logs-header main_heading">
                                          Logs
                                      </h2>
                                      <section className="device-log-table-container">
                                          <table className="device-log-table connected-table table">
                                              <thead>
                                                  <tr>
                                                      <th>Log</th>
                                                      <th>Date/Time</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {device?.logs.map(
                                                      (log: any, i: number) => {
                                                          return (
                                                              <tr key={i}>
                                                                  <td>
                                                                      {log.log}
                                                                  </td>
                                                                  <td>
                                                                      {moment(
                                                                          log.dateTime
                                                                      ).format(
                                                                          "HH:mm - DD/MM/YY"
                                                                      )}
                                                                  </td>
                                                              </tr>
                                                          );
                                                      }
                                                  )}
                                              </tbody>
                                          </table>
                                      </section>
                                  </>
                              ) : null}
                          </CustomTabPanel>
                      );
                  })
                : null}

            <Button
                variant="contained"
                className="close-room-btn"
                color="error"
                onClick={() => setOpenRoomModal(false)}
            >
                Close
            </Button>
        </Box>
    );
}
