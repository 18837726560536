import { io } from "socket.io-client";
const query = { query: { device: "Web Admin" } };
const socketInstance = io(`${process.env.REACT_APP_API_URL}`, query);

function setUpConnection() {
    if (!socketInstance.connected) {
        socketInstance.connect();
    }
}

const handleCon = () => {
    console.log("Im connected");
};

socketInstance.on("connect", handleCon);

// client-side
socketInstance.on("connect", () => {
    console.log(socketInstance.id);
});

socketInstance.on("disconnect", () => {
    console.log(socketInstance.id);
    console.log("DISCONNECTED");
});

export { setUpConnection };
