//React
import { useState, useEffect } from "react";
//Utils
import { getDevices, getEventTypes, getReasons } from "../utils/getters";
//Styling
import "./styles/admin.scss";
//MUI
import { Alert, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { axiosInstance } from "../utils/axiosInstance";

export default function Admin() {
    //Main
    const url = process.env.REACT_APP_API_URL;
    //Getters
    const [devices, setDevices] = useState<any[]>([]);
    const [reasons, setReasons] = useState<any[]>([]);
    const [eventTypes, setEventTypes] = useState<any[]>([]);
    //Inputs
    const [createReason, setCreateReason] = useState<string>("");
    const [createReasonPriority, setCreateReasonPriority] = useState<number>(0);
    const [selectedUpdateReason, setSelectedUpdateReason] = useState<number>(0);
    const [updateReasonPriority, setUpdateReasonPriority] = useState<number>(0);
    const [updateReason, setUpdateReason] = useState<string>("");
    const [createDevice, setCreateDevice] = useState<string>("");
    const [selectedUpdateDevice, setSelectedUpdateDevice] = useState<number>(0);
    const [updateDevice, setUpdateDevice] = useState<string>("");
    const [createEventType, setCreateEventType] = useState<string>("");
    const [selectedUpdateEventType, setSelectedUpdateEventType] =
        useState<number>(0);
    const [updateEventType, setUpdateEventType] = useState<string>("");
    //Alert
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<any>("");
    const [message, setMessage] = useState<string>("");
    const [disable, setDisable] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        getDevices().then((res) => {
            setDevices(res);
        });
        getEventTypes().then((res) => {
            setEventTypes(res);
        });
        getReasons().then((res) => {
            setReasons(res);
        });
    }, [reload]);

    const handleAlert = (message: string, severity: any) => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setDisable(true);
    };

    const handleCloseAlert = () => {
        setDisable(false);
        setMessage("");
        setSeverity("");
        setAlert(false);
    };

    const clearFields = () => {
        setCreateReason("");
        setCreateReasonPriority(0);
        setSelectedUpdateReason(0);
        setUpdateReasonPriority(0);
        setUpdateReason("");
        setCreateDevice("");
        setSelectedUpdateDevice(0);
        setUpdateDevice("");
        setCreateEventType("");
        setSelectedUpdateEventType(0);
        setUpdateEventType("");
        setReload(!reload);
    };

    const hanldeCreateReason = () => {
        let data = {
            name: createReason,
            priorityId: createReasonPriority,
        };
        if (createReason === "" || createReasonPriority === 0) {
            handleAlert("Please enter a Reason & priority level", "warning");
        } else {
            axiosInstance
                .post(`${url}/Reasons`, data)
                .then((res) => {
                    clearFields();
                    handleAlert("Reason created", "success");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleUpdateReason = () => {
        let data = {
            name: updateReason,
            priorityId: updateReasonPriority,
            reasonId: selectedUpdateReason,
        };
        if (
            selectedUpdateReason === 0 ||
            updateReasonPriority === 0 ||
            updateReason === ""
        ) {
            handleAlert(
                "Please select a reason to update and enter a replacement",
                "warning"
            );
        } else {
            axiosInstance
                .put(`${url}/Reasons`, data)
                .then((res) => {
                    clearFields();
                    handleAlert("Reason updated", "success");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleCreateDevice = () => {
        let data = {
            name: createDevice,
        };
        if (createDevice === "") {
            handleAlert("Please enter a Device", "warning");
        } else {
            axiosInstance
                .post(`${url}/Device`, data)
                .then((res) => {
                    clearFields();
                    handleAlert("Event Type created", "success");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const handleUpdateDevice = () => {
        let data = {
            name: updateDevice,
            deviceId: selectedUpdateDevice,
        };
        if (selectedUpdateDevice === 0 || updateDevice === "") {
            handleAlert(
                "Please select a device to update and enter a replacement",
                "warning"
            );
        } else {
            axiosInstance
                .put(`${url}/Device`, data)
                .then((res) => {
                    clearFields();
                    handleAlert("Device updated", "success");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const handleCreateEventType = () => {
        let data = {
            name: createEventType,
        };
        if (createEventType === "") {
            handleAlert("Please enter an Event Type", "warning");
        } else {
            axiosInstance
                .post(`${url}/EventTypes`, data)
                .then((res) => {
                    clearFields();
                    handleAlert("Event Type created", "success");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const handleUpdateEventType = () => {
        let data = {
            typeId: selectedUpdateEventType,
            name: updateEventType,
        };
        if (selectedUpdateEventType === 0 || updateEventType === "") {
            handleAlert(
                "Please select an event type to update and enter a replacement",
                "warning"
            );
        } else {
            axiosInstance
                .put(`${url}/EventTypes`, data)
                .then((res) => {
                    clearFields();
                    handleAlert("Event Type updated", "success");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <main className="admin">
            <h1 className="main_heading">Admin</h1>
            <div className="admin_sections">
                <section className="admin_section">
                    <section className="create_section">
                        <h2>Create Reason</h2>
                        <input
                            placeholder="Enter a new reason"
                            value={createReason}
                            onChange={(e) => setCreateReason(e.target.value)}
                        />
                        <select
                            onChange={(e) =>
                                setCreateReasonPriority(
                                    parseInt(e.target.value)
                                )
                            }
                            value={createReasonPriority}
                        >
                            <option value={0}>Select priority level</option>
                            <option value={1}>Low</option>
                            <option value={2}>Medium</option>
                            <option value={3}>High</option>
                        </select>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={hanldeCreateReason}
                            disabled={disable}
                        >
                            Create
                        </Button>
                    </section>
                    <section className="update_section">
                        <h2>Update Reason</h2>
                        <select
                            onChange={(e) =>
                                setSelectedUpdateReason(
                                    parseInt(e.target.value)
                                )
                            }
                            value={selectedUpdateReason}
                        >
                            <option value={0}>Select existing reason</option>
                            {reasons.length > 0
                                ? reasons.map((reason: any, i: number) => {
                                      return (
                                          <option
                                              key={i}
                                              value={reason.reasonId}
                                          >
                                              {reason.name}
                                          </option>
                                      );
                                  })
                                : null}
                        </select>
                        <select
                            onChange={(e) =>
                                setUpdateReasonPriority(
                                    parseInt(e.target.value)
                                )
                            }
                            value={updateReasonPriority}
                        >
                            <option value={0}>Select new priority level</option>
                            <option value={1}>Low</option>
                            <option value={2}>Medium</option>
                            <option value={3}>High</option>
                        </select>
                        <input
                            placeholder="Enter replacement reason"
                            onChange={(e) => setUpdateReason(e.target.value)}
                            value={updateReason}
                        />
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleUpdateReason}
                            disabled={disable}
                        >
                            Update
                        </Button>
                    </section>
                </section>
                <section className="admin_section">
                    <section className="create_section">
                        <h2>Create Device</h2>
                        <input
                            placeholder="Enter a new device"
                            value={createDevice}
                            onChange={(e) => setCreateDevice(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleCreateDevice}
                            disabled={disable}
                        >
                            Create
                        </Button>
                    </section>
                    <section className="update_section">
                        <h2>Update Device</h2>
                        <select
                            onChange={(e) =>
                                setSelectedUpdateDevice(
                                    parseInt(e.target.value)
                                )
                            }
                            value={selectedUpdateDevice}
                        >
                            <option value={0}>Select existing device</option>
                            {devices.length > 0
                                ? devices.map((device: any, i: number) => {
                                      return (
                                          <option
                                              key={i}
                                              value={device.deviceId}
                                          >
                                              {device.name}
                                          </option>
                                      );
                                  })
                                : null}
                        </select>
                        <input
                            placeholder="Enter replacement device"
                            onChange={(e) => setUpdateDevice(e.target.value)}
                            value={updateDevice}
                        />
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleUpdateDevice}
                            disabled={disable}
                        >
                            Update
                        </Button>
                    </section>
                </section>
                <section className="admin_section">
                    <section className="create_section">
                        <h2>Create Event Type</h2>
                        <input
                            placeholder="Enter a new event type"
                            value={createEventType}
                            onChange={(e) => setCreateEventType(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleCreateEventType}
                            disabled={disable}
                        >
                            Create
                        </Button>
                    </section>
                    <section className="update_section">
                        <h2>Update Event Type</h2>
                        <select
                            onChange={(e) =>
                                setSelectedUpdateEventType(
                                    parseInt(e.target.value)
                                )
                            }
                            value={selectedUpdateEventType}
                        >
                            <option value={0}>
                                Select existing event type
                            </option>
                            {eventTypes.length > 0
                                ? eventTypes.map(
                                      (eventType: any, i: number) => {
                                          return (
                                              <option
                                                  key={i}
                                                  value={eventType.typeId}
                                              >
                                                  {eventType.name}
                                              </option>
                                          );
                                      }
                                  )
                                : null}
                        </select>
                        <input
                            placeholder="Enter replacement event type"
                            onChange={(e) => setUpdateEventType(e.target.value)}
                            value={updateEventType}
                        />
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleUpdateEventType}
                            disabled={disable}
                        >
                            Update
                        </Button>
                    </section>
                </section>
            </div>
            {/* ALERT DIALOG */}
            <Dialog open={alert}>
                <section className="update_dialog">
                    <h2>Alert</h2>
                    <Alert severity={severity}>{message}</Alert>
                    <Button
                        variant="contained"
                        onClick={handleCloseAlert}
                        color="error"
                    >
                        Close
                    </Button>
                </section>
            </Dialog>
        </main>
    );
}
