//React
import { useState, useEffect } from "react";
import moment from "moment";
//Styling
import "./styles/findface.scss";
//MUI
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Alert } from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import Dialog from "@mui/material/Dialog";
//Components
import Loader from "./reuseables/Loader";
import IndvPing from "./IndvPing";
//TEST IMAGE
import MainLogo from "../images/nitrocam_logo.webp";
import { axiosInstance } from "../utils/axiosInstance";
import { getReasons } from "../utils/getters";

export default function FindFace() {
    //Main
    const url = process.env.REACT_APP_API_URL;
    //Face Details
    const [faceId, setFaceId] = useState<string>("");
    const [faceDetails, setFaceDetails] = useState<any>(null);
    const [pingData, setPingData] = useState<any[]>([]);
    const [reasons, setReasons] = useState<any[]>([]);
    //Alert
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<any>("");
    const [message, setMessage] = useState<string>("");
    const [disable, setDisable] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    //Dialogs
    const [updateReason, setUpdateReason] = useState<boolean>(false);
    const [updatePriority, setUpdatePriority] = useState<boolean>(false);

    useEffect(() => {
        getReasons().then((res) => setReasons(res));
    }, []);

    const handleAlert = (message: string, severity: any, duration: number) => {
        setDisable(true);
        setMessage(message);
        setSeverity(setSeverity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, duration);
    };

    const handleValidation = () => {
        if (faceId === "") {
            handleAlert("Please enter a face ID", "warning", 4000);
        } else {
            handleFaceLookup();
        }
    };

    const handleFaceLookup = async () => {
        setLoader(true);
        setFaceDetails(null);
        setPingData([]);

        await axiosInstance
            .get(`${url}/Face/find/${faceId}`)
            .then(async (res) => {
                if (!res.data) {
                    handleAlert(
                        "No Matches Found - Please try again",
                        "warning",
                        5000
                    );
                } else {
                    setFaceDetails(res.data);
                    setPingData(res.data.pingData);
                }
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Something went wrong - Please try again",
                    "error",
                    5000
                );
                setLoader(false);
            });
    };

    return (
        <main className="find_face">
            <h1 className="main_heading">Find Face</h1>
            <section className="face_lookup">
                <input
                    type="text"
                    placeholder="Enter Face ID"
                    value={faceId}
                    onChange={(e) => setFaceId(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleValidation();
                        }
                    }}
                />
                <Button
                    onClick={handleValidation}
                    variant="contained"
                    className="lookup_btn"
                    endIcon={<SearchIcon />}
                    disabled={disable}
                >
                    Lookup
                </Button>
            </section>
            <section className="alert_section">
                {alert ? (
                    <Alert className="alert_box" severity={severity}>
                        {message}
                    </Alert>
                ) : null}
            </section>
            {faceDetails !== null ? (
                !loader ? (
                    <section className="details_section">
                        {" "}
                        <div className="img_container">
                            <img
                                src={faceDetails.url}
                                alt="Image from face rec"
                                className="face_image"
                            />
                        </div>
                        <div className="header_details">
                            <h2>
                                Site:
                                <span>{faceDetails.siteName}</span>
                            </h2>
                            <h2>
                                Reason:
                                <span>{faceDetails.reasonName}</span>
                            </h2>
                            <h2>
                                Priority:
                                <span>{faceDetails.priorityName}</span>
                            </h2>
                            <h2>
                                Added:
                                <span>
                                    {moment(faceDetails.createdAt).format(
                                        "DD/MM/YY"
                                    )}
                                </span>
                            </h2>
                        </div>
                        {pingData?.length > 0 ? (
                            <table className="face_info_table table">
                                <thead>
                                    <tr>
                                        <th>Time Pinged</th>
                                        <th>Store</th>
                                        <th>Logs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pingData.map((ping: any, i: number) => {
                                        return <IndvPing key={i} ping={ping} />;
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <h2>There is no ping data for this face</h2>
                        )}
                    </section>
                ) : (
                    <Loader />
                )
            ) : null}

            {/* UPDATE DIALOG BOXES */}
            <Dialog open={updateReason}>
                <section className="update_dialog">
                    <h2>Update Reason</h2>
                    <section className="update_select">
                        <select>
                            <option>Please select reason</option>
                            {reasons.length > 0
                                ? reasons.map((reason: any, i: number) => {
                                      return (
                                          <option
                                              key={i}
                                              value={reason.reasonId}
                                          >
                                              {reason.name}
                                          </option>
                                      );
                                  })
                                : null}
                        </select>
                    </section>
                    <section className="btn_section">
                        <Button
                            variant="contained"
                            onClick={() => setUpdateReason(false)}
                            color="error"
                        >
                            Close
                        </Button>
                        <Button variant="contained" color="warning">
                            Update
                        </Button>
                    </section>
                </section>
            </Dialog>
        </main>
    );
}
