// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding: 2vh 0;
  height: 10vh;
  background-color: #151042;
}
nav .nav_nitropos_logo {
  width: 5%;
  min-width: 220px;
  cursor: pointer;
}
nav .nav_menu #menu_icon {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/nav.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EC2BI,aAAA;EACA,8BD3Bc;EC4Bd,mBD5B6B;EC6B7B,mBD7BqC;EC8BrC,SD9B0C;EAC1C,cAAA;EACA,YCaS;EDZT,yBCMe;ADHnB;AADI;EACI,SAAA;EACA,gBAAA;EACA,eAAA;AAGR;AACQ;EACI,WCTJ;ADUR","sourcesContent":["@import \"../../variables.scss\";\n\nnav {\n    @include flex(space-between, center, row, 10px);\n    padding: 2vh 0;\n    height: $nav-height;\n    background-color: $nitrocamDarkBlue;\n\n    .nav_nitropos_logo {\n        width: 5%;\n        min-width: 220px;\n        cursor: pointer;\n    }\n\n    .nav_menu {\n        #menu_icon {\n            color: $white;\n        }\n    }\n}\n","//Colors\n$nitroDark: #2c53a0;\n$nitroLight: #64c4e0;\n$background: #e4ffff;\n$fontColor: #4d4d4d;\n$error: #bb0000;\n$warning: #f29422;\n$white: #fff;\n$black: #000;\n$success: #b6fcc9;\n$green: #357a38;\n\n$nitrocamDarkBlue: #151042;\n$nitrocamBlue: #3b00f8;\n$nitrocamRed: #ff161b;\n$nitrocamYellow: #ffe169;\n\n//Heights\n$nav-height: 10vh;\n$main-height: 80vh;\n$footer-height: 6vh;\n\n//Breakpoints\n$mobile: 600px;\n$tablet: 1000px;\n$desktop: 1300px;\n\n//Layout\n@mixin flex($justify: center, $align: center, $direction: row, $gap: 10px) {\n    display: flex;\n    justify-content: $justify;\n    align-items: $align;\n    flex-direction: $direction;\n    gap: $gap;\n}\n\n//Overflow\n@mixin scrollbars(\n    $size,\n    $foreground-color,\n    $background-color: mix($foreground-color, white, 50%)\n) {\n    // For Google Chrome\n    &::-webkit-scrollbar {\n        width: $size;\n        height: $size;\n    }\n\n    &::-webkit-scrollbar-thumb {\n        background: $foreground-color;\n    }\n\n    &::-webkit-scrollbar-track {\n        background: $background-color;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
