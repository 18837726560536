//Packages
import moment from "moment";
//MUI
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80vh",
    overflowY: "auto",
    overFlowX: "auto",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 2,
    "@media(max-width: 1000px)": {
        width: "90vw",
    },
};

//Interfaces
interface Props {
    setLogsModal: any;
    logs: any[];
}

export default function LogsModal({ setLogsModal, logs }: Props) {
    console.log(logs.map(item=>item.log.includes('Failed')&& item))
    return (
        <Box sx={style} className="logs_modal">
            <h2>Logs</h2>
            <div className="table_container">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Log</th>
                            <th>Date/Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.length > 0 ? (
                            <>
                                {logs.map(function (d, i) {
                                    return (
                                        <tr>
                                            <td >{d.log.includes('Failed')? <div style={{backgroundColor:'rgb(238, 121, 121)', padding:'4px', width:'fit-content', fontWeight:600, borderRadius:4}}>{d.log}</div>:d.log}</td>
                                            <td>
                                                {moment(d.logTime).format(
                                                    "DD/MM/YYYY HH:mm:ss"
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </>
                        ) : null}
                    </tbody>
                </table>
            </div>
            <Button
                variant="contained"
                color="error"
                onClick={() => setLogsModal(false)}
            >
                Close
            </Button>
        </Box>
    );
}
