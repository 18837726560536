import { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axiosInstance";
import moment from "moment";

//Styling
import "./styles/home.scss";

export default function Home() {
    const [overview, setOverView] = useState<any>({});
    const [topFivePeople, setTopFivePeople] = useState<any>([]);
    const [statusBreakdown, setStatusBreakdown] = useState<any>([]);
    const [failedEvents, setFailedEvents] = useState<any>([]);
    useEffect(() => {
        axiosInstance
            .get(`${process.env.REACT_APP_API_URL}/Event/overview`)
            .then((res) => {
                setOverView(res.data);
                setTopFivePeople(res.data.topFivePeople);
                setStatusBreakdown(res.data.statusBreakdown);
                setFailedEvents(res.data.failedEvents);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <main className="home">
            <h1 className="main_heading">Daily Overview</h1>
            <section className="home_overview">
                <div className="overview_column">
                    <div className="overview_section">
                        <h2>No. of Events</h2>
                        <h4>{overview?.dailyCount}</h4>
                    </div>
                    <div className="overview_section">
                        <h2>Top 5 Faces</h2>
                        {topFivePeople.length > 0 ? (
                            <table className="table overview_table">
                                <thead>
                                    <tr>
                                        <th>Face Name</th>
                                        <th>End Location</th>
                                        <th>Daily Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {topFivePeople.map(
                                        (people: any, i: number) => {
                                            return (
                                                <tr>
                                                    <td>{people.faceName}</td>
                                                    <td>
                                                        {" "}
                                                        {people.endLocation[0].toUpperCase() +
                                                            people.endLocation.slice(
                                                                1
                                                            )}
                                                    </td>
                                                    <td>{people.count}</td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <h4>None</h4>
                        )}
                    </div>
                </div>
                <div className="overview_column">
                    <div className="overview_section">
                        <h2>Status Totals</h2>
                        {statusBreakdown.length > 0 ? (
                            <table className="table status_table">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {statusBreakdown.map(
                                        (statusRow: any, i: number) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {statusRow.statusName}
                                                    </td>
                                                    <td>{statusRow.total}</td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <h4>None</h4>
                        )}
                    </div>
                    <div className="overview_section">
                        <h2>Failed Events</h2>
                        {failedEvents.length > 0 ? (
                            <table className="table failed_table">
                                <thead>
                                    <tr>
                                        <th>Event Id</th>
                                        <th>End Location</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {failedEvents.map(
                                        (failedEvent: any, i: number) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {failedEvent.eventId}
                                                    </td>
                                                    <td>
                                                        {failedEvent.endLocation[0].toUpperCase() +
                                                            failedEvent.endLocation.slice(
                                                                1
                                                            )}
                                                    </td>
                                                    <td>
                                                        {moment(
                                                            failedEvent.createdAt
                                                        ).format(
                                                            "DD/MM/YY HH:mm:ss"
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <h4>None</h4>
                        )}
                    </div>
                </div>
            </section>
        </main>
    );
}
