import { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axiosInstance";
import "./styles/historic.scss";
import moment from "moment";
import { Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function Historic() {
    const [events, setEvents] = useState<any>([]);
    const [date, setDate] = useState<string>(
        moment(Date.now()).format("YYYY-MM-DD")
    );
    const [page, setPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>(0);
    const offset: number = 10;
    useEffect(() => {
        let axiosData = {
            date,
            offset,
            page,
        };
      
        axiosInstance
            .post(`${process.env.REACT_APP_API_URL}/Event/all`, axiosData)
            .then((res) => {
                setEvents(res.data.events);
                setLastPage(Math.ceil(res.data.totalCount / offset));
            })
            .catch((err) => {
                console.log(err);
            });
    }, [date, page]);

    return (
        <main className="historic">
            {events.length > 0 ? (
                <p className="page_number">
                    {page}/{lastPage}
                </p>
            ) : null}
            <section className="historic_search">
                {" "}
                {events.length > 0 ? (
                    <Button
                        onClick={() => {
                            if (page != 1) {
                                setPage(page - 1);
                            }
                        }}
                        disabled={page === 1}
                        variant="contained"
                    >
                        <KeyboardBackspaceIcon />
                    </Button>
                ) : null}
                <input
                    type="date"
                    onChange={(e) => {
                        setPage(1);
                        setDate(moment(e.target.value).format("YYYY-MM-DD"));
                    }}
                    className="historic_date_select"
                />
                {events.length > 0 ? (
                    <Button
                        onClick={() => {
                            setPage(page + 1);
                        }}
                        disabled={events.length < offset}
                        variant="contained"
                    >
                        <ArrowForwardIcon />
                    </Button>
                ) : null}
            </section>

            {events.length > 0 ? (
                <table className="historic_table table">
                    <thead>
                        <tr>
                            <th>Event Id</th>
                            <th>End Location</th>
                            <th>Type</th>
                            <th>Priority</th>
                            <th>Status</th>
                            <th>Created</th>
                            <th>Completed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.map((event: any, i: number) => {
                            return (
                                <tr>
                                    <td>{event.eventId}</td>
                                    <td>
                                        {event.endLocation[0].toUpperCase() +
                                            event.endLocation.slice(1)}
                                    </td>
                                    <td>{event.typeName}</td>
                                    <td>{event.priorityName}</td>
                                    <td>{event.statusName}</td>
                                    <td>
                                        {moment(event.createdAt).format(
                                            "DD/MM/YY HH:mm:ss"
                                        )}
                                    </td>
                                    <td>
                                        {event.completedAt
                                            ? moment(event.completedAt).format(
                                                  "DD/MM/YY HH:mm:ss"
                                              )
                                            : "Not Completed"}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <h1>No Events</h1>
            )}
        </main>
    );
}
