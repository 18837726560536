// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not_found {
  height: 100vh;
  background-color: #151042;
}
.not_found img {
  margin-top: 15vh;
  width: 300px;
  max-width: 320px;
}
.not_found h1 {
  font-size: 1.5rem;
  padding: 0 2vw;
  color: #fff;
}
.not_found p {
  font-size: 2rem;
  color: #ffe169;
}
.not_found p span {
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/notFound.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,yBCSe;ADTnB;AACI;EACI,gBAAA;EACA,YAAA;EACA,gBAAA;AACR;AACI;EACI,iBAAA;EACA,cAAA;EACA,WCLA;ADMR;AACI;EACI,eAAA;EACA,cCDS;ADEjB;AAAQ;EACI,0BAAA;EACA,WCZJ;EDaI,eAAA;AAEZ","sourcesContent":["@import \"../../variables.scss\";\n.not_found {\n    height: 100vh;\n    background-color: $nitrocamDarkBlue;\n    img {\n        margin-top: 15vh;\n        width: 300px;\n        max-width: 320px;\n    }\n    h1 {\n        font-size: 1.5rem;\n        padding: 0 2vw;\n        color: $white;\n    }\n    p {\n        font-size: 2rem;\n        color: $nitrocamYellow;\n        span {\n            text-decoration: underline;\n            color: $white;\n            cursor: pointer;\n        }\n    }\n}\n","//Colors\n$nitroDark: #2c53a0;\n$nitroLight: #64c4e0;\n$background: #e4ffff;\n$fontColor: #4d4d4d;\n$error: #bb0000;\n$warning: #f29422;\n$white: #fff;\n$black: #000;\n$success: #b6fcc9;\n$green: #357a38;\n\n$nitrocamDarkBlue: #151042;\n$nitrocamBlue: #3b00f8;\n$nitrocamRed: #ff161b;\n$nitrocamYellow: #ffe169;\n\n//Heights\n$nav-height: 10vh;\n$main-height: 80vh;\n$footer-height: 6vh;\n\n//Breakpoints\n$mobile: 600px;\n$tablet: 1000px;\n$desktop: 1300px;\n\n//Layout\n@mixin flex($justify: center, $align: center, $direction: row, $gap: 10px) {\n    display: flex;\n    justify-content: $justify;\n    align-items: $align;\n    flex-direction: $direction;\n    gap: $gap;\n}\n\n//Overflow\n@mixin scrollbars(\n    $size,\n    $foreground-color,\n    $background-color: mix($foreground-color, white, 50%)\n) {\n    // For Google Chrome\n    &::-webkit-scrollbar {\n        width: $size;\n        height: $size;\n    }\n\n    &::-webkit-scrollbar-thumb {\n        background: $foreground-color;\n    }\n\n    &::-webkit-scrollbar-track {\n        background: $background-color;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
