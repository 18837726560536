import { useState } from "react";
//Components
import OfflineModal from "./OfflineModal";
//MUI
import { Modal } from "@mui/material";

interface Props {
    site: any;
}

export default function Site({ site }: Props) {
    const [openSiteModal, setOpenSiteModal] = useState(false);
    return (
        <>
            <div className="room-card" onClick={() => setOpenSiteModal(true)}>
                <h2>{site?.room}</h2>
            </div>
            <Modal open={openSiteModal} onClose={() => setOpenSiteModal(false)}>
                <OfflineModal site={site} setOpenSiteModal={setOpenSiteModal} />
            </Modal>
        </>
    );
}
