//React
import { useState } from "react";
import moment from "moment";
//MUI
import { Button, Modal } from "@mui/material";
import TocIcon from "@mui/icons-material/Toc";
import { useNavigate } from "react-router-dom";
//Components
import LogsModal from "./modal/LogsModal";

interface Props {
    ping: any;
}

export default function IndvPing({ ping }: Props) {
    const navigate = useNavigate()
    const [logsModal, setLogsModal] = useState<boolean>(false);
console.log(ping)


    return (
        <>
            <tr>
                <td>{moment(ping.createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                <td>{ping.endLocation.toUpperCase()}</td>
                <td>
                    <Button
                        variant="contained"
                        className="log_btn"
                        onClick={() => setLogsModal(true)}
                    >
                        <TocIcon />
                    </Button>
                </td>
                <td>
                    {ping?.uuid && <Button
                        variant="contained"
                        className="log_btn"
                        onClick={() => navigate(`/match-face/${ping.uuid}`)}
                    >
                        Show match details
                    </Button>}
                </td>
                {/* LOGS MODAL */}
                <Modal open={logsModal}>
                    <LogsModal setLogsModal={setLogsModal} logs={ping.logs} />
                </Modal>
            </tr>
        </>
    );
}
