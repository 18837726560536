//React
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
//Components
import Login from "./components/Login";
import Nav from "./components/Nav";
import Home from "./components/Home";
import AddFace from "./components/AddFace";
import FindFace from "./components/FindFace";
import Reporting from "./components/reporting/Reporting";
import Admin from "./components/Admin";
import Historic from "./components/Historic";
import Footer from "./components/Footer";
import { NitrocamProtect } from "./components/Auth/NitrocamProtect";
import NotFound from "./components/NotFound";
//Styling
import "./App.css";
import "./reuseables.scss";
//Sentry
import { configSentry } from "./utils/sentry";
//Socket
import { setUpConnection } from "./utils/socket";
import MatchDetailsPage from "./components/MatchDetailsPage";

//TODO: SENTRY SETUP
// configSentry();

function App() {
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        setUpConnection();
        //TODO: ASCERTAIN IF LOGGED IN
        let token = localStorage.getItem("authToken");
        if (token) {
            setLoggedIn(true);
        }
    }, []);

    return (
        <div className="App">
            <Router>
                {loggedIn ? <Nav setLoggedIn={setLoggedIn} /> : null}

                <Routes>
                    <Route
                        path="/login"
                        element={<Login setLoggedIn={setLoggedIn} />}
                    />
                    <Route element={<NitrocamProtect />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/add-face" element={<AddFace />} />
                        <Route path="/find-face" element={<FindFace />} />
                        <Route path="/reporting" element={<Reporting />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/historic" element={<Historic />} />
                    </Route>
                        <Route path="/match-face/:uuid" element={<MatchDetailsPage/>} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                {loggedIn ? <Footer /> : null}
            </Router>
        </div>
    );
}

export default App;
