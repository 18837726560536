//React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Packages
import bcrypt from "bcryptjs-react";
import CryptoJS from "crypto-js";
//Styling
import "./styles/login.scss";
import MainLogo from "../images/nitrocam_logo.webp";
//MUI
import { Button, Alert } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
//Utils
import { emailRegex } from "../utils/regex";
import { axiosInstance } from "../utils/axiosInstance";

//Interfaces
interface Props {
    setLoggedIn: any;
}

export default function Login({ setLoggedIn }: Props) {
    //Main
    const url = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    //Encryption
    const emailSalt = bcrypt.genSaltSync(10);
    const passwordSalt = bcrypt.genSaltSync(10);
    //Login Details
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordToggle, setPasswordToggle] = useState(false);
    //Alert
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<any>("");
    const [message, setMessage] = useState<string>("");
    const [disable, setDisable] = useState<boolean>(false);

    useEffect(() => {
        //If on login screen clear storage for relogin
        localStorage.clear();
    }, []);

    const handleAlert = (message: string, severity: any, duration: number) => {
        setDisable(true);
        setMessage(message);
        setSeverity(setSeverity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, duration);
    };

    const handleValidation = () => {
        if (email === "") {
            handleAlert("Please enter your email address", "warning", 4000);
        } else if (!email.match(emailRegex)) {
            handleAlert("Please enter a valid email address", "warning", 4000);
        } else if (password === "") {
            handleAlert("Please enter your password", "warning", 4000);
        } else {
            handleLogin();
        }
    };

    const handleLogin = () => {
        const hashedEmail = CryptoJS.AES.encrypt(email, `${process.env.REACT_APP_ENC_KEY}`).toString();;
        const hashedPassword = CryptoJS.AES.encrypt(password, `${process.env.REACT_APP_ENC_KEY}`).toString();
        let data = {
            email: hashedEmail,
            password: hashedPassword,
        };

        axiosInstance
            .post(`${url}/Users/login`, data)
            .then(async (res) => {
                if (res.data.auth == true) {
                    localStorage.setItem(
                        "userDetails",
                        JSON.stringify({
                            user: "Test",
                            role: "Developer",
                        })
                    );
                    localStorage.setItem("authToken", "TESTTOKEN");
                    setLoggedIn(true);
                    navigate("/");
                } else {
                    handleAlert(
                        "Incorrect Credentials - Please try again",
                        "error",
                        3000
                    );
                }
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Something went wrong - Please try again",
                    "error",
                    5000
                );
            });
    };

    return (
        <main className="login">
            <section className="login_box">
                <img src={MainLogo} alt="Nitrocam Main Logo" />
                <h2>Please Login</h2>
                <input
                    type="text"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="password_section">
                    <input
                        placeholder="Enter Password"
                        value={password}
                        type={passwordToggle ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                                handleLogin();
                            }
                        }}
                    />
                    {passwordToggle ? (
                        <VisibilityIcon
                            className="search_btn"
                            onClick={() => setPasswordToggle(!passwordToggle)}
                        />
                    ) : (
                        <VisibilityOffIcon
                            className="search_btn"
                            onClick={() => setPasswordToggle(!passwordToggle)}
                        />
                    )}
                </div>
                <Button
                    variant="contained"
                    className="login_btn"
                    disabled={disable}
                    onClick={handleValidation}
                >
                    Login
                </Button>
            </section>
            <section className="alert_section">
                {alert ? (
                    <Alert className="alert_box" severity={severity}>
                        {message}
                    </Alert>
                ) : null}
            </section>
        </main>
    );
}
