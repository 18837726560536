import React from "react";
import "./styles/findface.scss";
import "./styles/matchface.scss";
import moment from "moment";
import IndvPing from "./IndvPing";
import {useState, useEffect } from 'react';
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import {
    AlertTitle,
    Stack,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider,
} from "@mui/material";


const MatchDetailsPage = () => {
 
  const [data, setData] = useState<any>(null)
  const [detailsOpen, setDetailsOpen] = useState<any>(null)

  const [error, setError] = useState( null )
  const {uuid} = useParams()

  let baseUrl:any = process.env.REACT_APP_API_URL;

  let url:string = `${baseUrl}/match/${uuid}`

  const getMatchedData = async(url:string)=>{
    let res = await axios.get(url)
    return res?.data
  }
  useEffect( ()=>{
    getMatchedData(url).then((res)=> {console.log('data is here' ,res);setData(res?.matchedData)}).catch(err=>setError(err))
  }, [])

  const detailsStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxHeight: "90vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
};

  return (
    <div>
      <main className="match_face">
        <h1 className="main_heading">Match Face</h1>
        {/* <section className="alert_section">Alert comes here</section> */}
        <section className="match_content">
          <section className="first_div">
            <section className="both_images">
              <section className="img_section">
                {" "}
                <div
                  className="img_container_original"
                  //  style={{opacity:'10%', backgroundColor:'black'}}
                >
                  <img
                    src={data?.originalImg} alt="Image from face rec"
                    className="face_image"
                  />
                  <div className="mask_face_image bar">
                    <div className="box-outer">
                      <div className="bar top"></div>
                      <div className="bar right delay"></div>
                      <div className="bar bottom delay"></div>
                      <div className="bar left"></div>
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    fontStyle: "italic",
                    fontWeight: "normal",
                  }}
                >
                  {" "}
                  Original Image
                </p>
              </section>
              <section className="img_section">
                {" "}
                <div className="img_container_match">
                  <img
                    src={data?.spottedImg}
                    alt="Image from face rec"
                    className="face_image"
                  />
                </div>
                <p> Matched Image</p>
              </section>
            </section>

            <div className="card">
              <div className="card-header">Incident Report</div>
              <div className="card-body">
                <div className="card-row">
                  <span className="label">Items:</span>
                  <span className="value">{data?.items}</span>
                </div>
                <div className="card-row">
                  <span className="label">Description:</span>
                  <textarea value={data?.description} disabled style={{flex:3, textAlign:'left',  border:'1px solid grey', padding:'0.7rem', fontSize:'14px', fontWeight:'400px', height:'100px', borderRadius:'3px'}}>
                    
                  </textarea>
                </div>
                <div className="card-row">
                  <span className="label">Person:</span>
                  <span className="value">{data?.person}</span>
                </div>
                <div className="card-row">
                  <span className="label">Site:</span>
                  <span className="value">{data?.site}</span>
                </div>
                <div className="card-row">
                  <span className="label">Status:</span>
                  <span className="value">{data?.statusName}</span>
                </div>
              </div>
            </div>
          </section>
          <section className="second_div">
            <div className="card_2">
              <div className="card-header">Details</div>
              <div className="card-body">
                <div className="card-row">
                  <span className="label" >Reason:</span>
                  <span className="value" style={data?.reason && {backgroundColor:'rgb(226, 36, 36)' ,padding:'0.5em',color:'white' , fontWeight:'400px'}}>{data?.reason}</span>
                </div>
                {/* <div className="card-row">
                  <span className="label">Site:</span>
                  <span className="value">{data?.site}</span>
                </div> */}

                <div className="card-row">
                  <span className="label">Action Taken:</span>
                  <span className="value">{data?.metaData?.actionTaken}</span>
                </div>
                <div className="card-row">
                  <span className="label">Added:</span>
                  <span className="value">
                    {moment(data?.metaData?.createdAt).format("DD/MM/YY")}
                  </span>
                </div>
                <div className="card-row">
                  <span className="label">Completed At:</span>
                  <span className="value">
                    {moment(data?.metaData?.completedAt).format("DD/MM/YY")}
                  </span>
                </div>
                <div className="card-row">
                  <span className="label">End Location:</span>
                  <span className="value">
                    {data?.metaData?.endLocation}
                  </span>
                </div>
              </div>
            </div>
            <div className="card_2">
              <div className="card-header">CCTV info</div>
              <div className="card-body">
                <div className="card-row">
                  <span className="label">Priority:</span>
                  <span className="value">{data?.metaData?.name}</span>
                </div>
                <div className="card-row">
                  <span className="label">CCTV Info:</span>
                  <span className="value">
                    {/* Face_Req Event not sent as 0 Connected Users for Site */}
                    --
                  </span>
                </div>
                <div className="card-row">
                  <span className="label">CCTV Notes:</span>
                  <span className="value">
                    <button className="button" onClick={()=>setDetailsOpen(true)}>Show Notes</button>
                  </span>
                </div>
              </div>
              {/* <div className="card_footer">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset.
              </div> */}
            </div>
          </section>
        </section>

        {/* {<div style={{padding: '0px 4rem', marginBottom:'8rem'}}> */}
            
        <Modal
                        open={detailsOpen}
                        onClose={() => setDetailsOpen(false)}
                        aria-labelledby="details-modal-title"
                        aria-describedby="details-modal-description"
                    >
                        <Box sx={detailsStyle}>
                            <Typography
                                id="details-modal-title"
                                variant="h5"
                                component="h2"
                            >
                                CCTV Request Details
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            {/* <div id="details-modal-description"> */}
            
            <TableContainer
                                            component={Paper}
                                            sx={{ maxHeight: 400 }}
                                        >
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Note
                                                        </TableCell>
                                                        <TableCell>
                                                            Note Date
                                                        </TableCell>
                                                        <TableCell>
                                                            User
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data?.notes.map(
                                                        (
                                                            note: any,
                                                            index: number
                                                        ) => (
                                                            <TableRow
                                                                key={index}
                                                            >
                                                                <TableCell>
                                                                    {note.note}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        note.noteDate
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {note.user}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* </div> */}
                                        </Box>
                                        </Modal>
                                        
        {/* {data?.length > 0 ? (
          <table className="face_info_table table">
            <thead>
              <tr>
                <th>Time Pinged</th>
                <th>Store</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((ping: any, i: number) => {
                return <IndvPing key={i} ping={ping} />;
              })}
            </tbody>
          </table>
        ) : (
          <h2>There is no ping data for this face</h2>
        )} */}
      </main>

      {/* <main className="match_face">
        <h1 className="main_heading">Match Face</h1>
        <section className="face_lookup"></section>
        <section className="alert_section"></section>
        <section className="first_div">
          <section className="both_images">
            <section className="img_section">
              {" "}
              <div
                className="img_container_original"
                //  style={{opacity:'10%', backgroundColor:'black'}}
              >
                <img
                  src="https://plus.unsplash.com/premium_photo-1689568126014-06fea9d5d341?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D"
                  alt="Image from face rec"
                  className="face_image"
                />
                <div className="mask_face_image bar">
                  <div className="box-outer">
                    <div className="bar top"></div>
                    <div className="bar right delay"></div>
                    <div className="bar bottom delay"></div>
                    <div className="bar left"></div>
                  </div>
                </div>
              </div>
              <p
                style={{
                  fontStyle: "italic",
                  fontWeight: "normal",
                }}
              >
                {" "}
                Original Image
              </p>
            </section>
            <section className="img_section">
              {" "}
              <div className="img_container_match">
                <img
                  src="https://plus.unsplash.com/premium_photo-1689568126014-06fea9d5d341?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D"
                  alt="Image from face rec"
                  className="face_image"
                />
              </div>
              <p> Matched Image</p>
            </section>
          </section>

          <section className="match_details_section">
            {" "}
        
            <div className="card">
              <div className="card-header">Details</div>
              <div className="card-body">
                <div className="card-row">
                  <span className="label">Reason:</span>
                  <span className="value">{temp.reasonName}</span>
                </div>
                <div className="card-row">
                  <span className="label">Site:</span>
                  <span className="value">{temp.siteName}</span>
                </div>

                <div className="card-row">
                  <span className="label">Action Taken:</span>
                  <span className="value">* NO ACTION TAKEN -- * </span>
                </div>
                <div className="card-row">
                  <span className="label">Added:</span>
                  <span className="value">
                    {moment(temp.createdAt).format("DD/MM/YY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="card_2">
              <div className="card-header">CCTV info</div>
              <div className="card-body">
                <div className="card-row">
                  <span className="label">Reason:</span>
                  <span className="value">{temp.reasonName}</span>
                </div>
                <div className="card-row">
                  <span className="label">Site:</span>
                  <span className="value">{temp.siteName}</span>
                </div>
                <div className="card-row">
                  <span className="label">Priority:</span>
                  <span className="value">{temp.priorityName}</span>
                </div>
                <div className="card-row">
                  <span className="label">CCTV Info:</span>
                  <span className="value">
                    Face_Req Event not sent as 0 Connected Users for Site
                  </span>
                </div>
                <div className="card-row">
                  <span className="label">CCTV Info:</span>
                  <span className="value">
                    <button className="button">Show Notes</button>
                  </span>
                </div>
              </div>
              <div className="card_footer">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset.
              </div>
            </div>
          </section>
        </section>
        <section className="second_div">
          <section className="cctv_info">
            <div className="desc_card">
              <div className="card">
                <div className="card-header">Incident Report</div>
                <div className="card-body">
                  <div className="card-row">
                    <span className="label">Items:</span>
                    <span className="value">Pistachio bars</span>
                  </div>
                  <div className="card-row">
                    <span className="label">Description:</span>
                    <span className="value">
                      Rubbeese aisle first fridge from door side, 1 pistachio
                      bar was stolen between 1834 and bow
                    </span>
                  </div>
                  <div className="card-row">
                    <span className="label">Person:</span>
                    <span className="value">Not sure</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="todo"> </section>
        </section>

        {data?.length > 0 ? (
          <table className="face_info_table table">
            <thead>
              <tr>
                <th>Time Pinged</th>
                <th>Store</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((ping: any, i: number) => {
                return <IndvPing key={i} ping={ping} />;
              })}
            </tbody>
          </table>
        ) : (
          <h2>There is no ping data for this face</h2>
        )}
      </main> */}
    </div>
  );
};

export default MatchDetailsPage;
