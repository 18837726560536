//React
import { Navigate, Outlet } from "react-router-dom";

const NitrocamProtect = () => {
    let authorised = false;
    let userDetails = localStorage.getItem("userDetails");

    let parsedUser;
    if (userDetails) {
        parsedUser = JSON.parse(userDetails);
        if (
            parsedUser.role === "Developer"
            //TODO: ROLES TO BE ADDED
        ) {
            authorised = true;
        }
    }

    if (!authorised) {
        return <Navigate to="/off-limits" replace />;
    }
    return <Outlet />;
};

export { NitrocamProtect };
