/*//? REPORTING SECTION DOCUMENTATION (09-12-24)
//?=====================================================================
//? THIS SECTION HOUSES ALL NITROCAM REPORTS
//? CREATE A NEW FOLDER FOR EACH REPORT
//? IMPORT THE HOME COMPONENT FOR EACH REPORT FODLER INTO THIS FILE
//? ADD THE HOME REPORT COMPONENT INTO BOTH THE TABS (DESKTOP) 
//? & DRAWER (MOBILE) SECTIONS
//?=====================================================================
 */

import { useState } from "react";
//Components
import ConnectedDevices from "./reports/connectedDevices/ConnectedDevices";
import OfflineDevices from "./reports/offlineDevices/OfflineDevices";
//MUI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import MenuBookIcon from "@mui/icons-material/MenuBook";

//Styling
import "./reporting.scss";

//TABS =======================================================
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
//TABS =======================================================

export default function Reporting() {
    //TABS=======================================
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    //TABS=========================================

    //DRAWER=======================================
    const [open, setOpen] = useState(false);
    const [openPageName, setOpenPageName] = useState("Connected Devices");

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box
            sx={{ width: 250, height: "100%", backgroundColor: "#ffe169" }}
            role="presentation"
            onClick={toggleDrawer(false)}
        >
            <List>
                {["Connected Devices", "Offline Devices"].map((text, index) => (
                    <>
                        {" "}
                        <ListItem
                            key={text}
                            disablePadding
                            onClick={() => setOpenPageName(text)}
                        >
                            <ListItemButton>
                                <ListItemText
                                    primary={text}
                                    sx={{
                                        color: "#151042",
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </>
                ))}
            </List>
        </Box>
    );
    // DRAWER========================================================

    return (
        <main className="reporting-page">
            <h1 className="main_heading reporting-heading">
                Reporting{" "}
                <span className="mobile-heading">- {openPageName}</span>
            </h1>
            <Box className="reporting-tab-container">
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Connected Devices" {...a11yProps(0)} />
                        <Tab label="Offline Devices" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <ConnectedDevices />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <OfflineDevices />
                </CustomTabPanel>
            </Box>
            <div className="reporting-drawer-container">
                <Button
                    onClick={toggleDrawer(true)}
                    className="drawer-btn"
                    variant="contained"
                >
                    Select Report
                </Button>
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
                {openPageName === "Connected Devices" ? (
                    <ConnectedDevices />
                ) : openPageName === "Offline Devices" ? (
                    <OfflineDevices />
                ) : null}
            </div>
        </main>
    );
}
