import "./styles/notFound.scss";
import Logo from "../images/nitrocam_logo.webp";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();
    return (
        <main className="not_found">
            <img src={Logo} />
            <h1>
                Why did the camera stop dreaming about a career in photography?
                He couldn’t remain focused.
            </h1>
            <p>
                Click <span onClick={() => navigate("/login")}>here</span> to go
                back to login
            </p>
        </main>
    );
}
