import { useEffect, useState } from "react";
import moment from "moment";
//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//MUI - Graphs
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";

interface Props {
    site: any;
    setOpenSiteModal: any;
}

//TABS =======================================================
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
//TABS =======================================================

export default function OfflineModal({ site, setOpenSiteModal }: Props) {
    //TABS=======================================
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    //TABS=========================================
    console.log(site);
    return (
        <Box className="offline-devices-room-modal">
            <h2 className="main_heading room-header">Room: {site.room}</h2>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {site?.devices?.length > 0
                        ? site?.devices.map((device: any, i: number) => {
                              return (
                                  <Tab
                                      label={
                                          device.deviceName
                                              ? device.deviceName
                                              : i + 1
                                      }
                                      {...a11yProps(i)}
                                  />
                              );
                          })
                        : null}
                </Tabs>
            </Box>{" "}
            {site?.devices?.length > 0
                ? site?.devices.map((device: any, i: number) => {
                      return (
                          <CustomTabPanel value={value} index={i}>
                              <section className="device-header-information">
                                  <div className="single-info">
                                      <h2>Device Name</h2>
                                      <p>
                                          {device.deviceName
                                              ? device.deviceName
                                              : "No Device Name"}
                                      </p>
                                  </div>
                                  <div className="single-info">
                                      <h2>Total Life Time</h2>
                                      <p>
                                          {`${Math.floor(
                                              moment
                                                  .duration(
                                                      device.totalTime,
                                                      "seconds"
                                                  )
                                                  .asDays()
                                          )}Days : ${moment
                                              .duration(
                                                  device.totalTime,
                                                  "seconds"
                                              )
                                              .hours()}Hours : ${moment
                                              .duration(
                                                  device.totalTime,
                                                  "seconds"
                                              )
                                              .minutes()}Minutes : ${moment
                                              .duration(
                                                  device.totalTime,
                                                  "seconds"
                                              )
                                              .seconds()}seconds`}
                                      </p>
                                  </div>{" "}
                                  <div className="single-info">
                                      <h2>Total Online Time</h2>
                                      <p>
                                          {`${Math.floor(
                                              moment
                                                  .duration(
                                                      device.totalTime -
                                                          device.offlineTime,
                                                      "seconds"
                                                  )
                                                  .asDays()
                                          )}Days : ${moment
                                              .duration(
                                                  device.totalTime -
                                                      device.offlineTime,
                                                  "seconds"
                                              )
                                              .hours()}Hours : ${moment
                                              .duration(
                                                  device.totalTime -
                                                      device.offlineTime,
                                                  "seconds"
                                              )
                                              .minutes()}Minutes : ${moment
                                              .duration(
                                                  device.totalTime -
                                                      device.offlineTime
                                              )
                                              .seconds()}seconds`}
                                      </p>
                                  </div>{" "}
                                  <div className="single-info">
                                      <h2>Total Offline Time</h2>
                                      <p>
                                          {`${Math.floor(
                                              moment
                                                  .duration(
                                                      device.offlineTime,
                                                      "seconds"
                                                  )
                                                  .asDays()
                                          )}Days : ${moment
                                              .duration(
                                                  device.offlineTime,
                                                  "seconds"
                                              )
                                              .hours()}Hours : ${moment
                                              .duration(
                                                  device.offlineTime,
                                                  "seconds"
                                              )
                                              .minutes()}Minutes : ${moment
                                              .duration(
                                                  device.offlineTime,
                                                  "seconds"
                                              )
                                              .seconds()}seconds`}
                                      </p>
                                  </div>
                                  <PieChart
                                      colors={["#ffe169", "#151042"]}
                                      series={[
                                          {
                                              data: [
                                                  {
                                                      id: 0,
                                                      value:
                                                          100 -
                                                          device.percentageOffline.toFixed(
                                                              2
                                                          ),
                                                      label: `On - ${
                                                          100 -
                                                          device.percentageOffline.toFixed(
                                                              2
                                                          )
                                                      }%`,
                                                  },
                                                  {
                                                      id: 1,
                                                      value: device.percentageOffline.toFixed(
                                                          2
                                                      ),
                                                      label: `Off - ${device.percentageOffline.toFixed(
                                                          2
                                                      )}%`,
                                                  },
                                              ],
                                          },
                                      ]}
                                      width={400}
                                      height={200}
                                  />
                                  {device?.weeklyStatus?.length > 0 ? (
                                      <LineChart
                                          dataset={device?.weeklyStatus}
                                          xAxis={[
                                              {
                                                  label: "Day of the week",
                                                  scaleType: "band",
                                                  dataKey: "date",
                                                  valueFormatter: (date) =>
                                                      `${date}`,
                                              },
                                          ]}
                                          yAxis={[
                                              {
                                                  label: "Offline Time (s)",
                                              },
                                          ]}
                                          series={[
                                              {
                                                  data: [
                                                      device?.weeklyStatus[0]
                                                          .offlineTime,
                                                      device?.weeklyStatus[1]
                                                          .offlineTime,
                                                      device?.weeklyStatus[2]
                                                          .offlineTime,
                                                      device?.weeklyStatus[3]
                                                          .offlineTime,
                                                      device?.weeklyStatus[4]
                                                          .offlineTime,
                                                      device?.weeklyStatus[5]
                                                          .offlineTime,
                                                      device?.weeklyStatus[6]
                                                          .offlineTime,
                                                  ],
                                              },
                                          ]}
                                          width={500}
                                          height={300}
                                      />
                                  ) : null}
                              </section>
                              {device?.logs?.length > 0 ? (
                                  <>
                                      <h2 className="logs-header main_heading">
                                          Logs
                                      </h2>
                                      <section className="device-log-table-container">
                                          <table className="device-log-table offline-table table">
                                              <thead>
                                                  <tr>
                                                      <th>Offline Time</th>
                                                      <th>Online Time</th>
                                                      <th>Duration</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {device?.logs.map(
                                                      (log: any, i: number) => {
                                                          //Get the time duration the device was offline
                                                          const _offline =
                                                              moment(
                                                                  log.offlineTime
                                                              );
                                                          const _online =
                                                              moment(
                                                                  log.onlineTime
                                                              );
                                                          const differenceInSeconds =
                                                              _online.diff(
                                                                  _offline,
                                                                  "seconds"
                                                              );
                                                          const differenceInMinutes =
                                                              _online.diff(
                                                                  _offline,
                                                                  "minutes"
                                                              );
                                                          const differenceInHours =
                                                              _online.diff(
                                                                  _offline,
                                                                  "hours"
                                                              );
                                                          const differenceInDays =
                                                              _online.diff(
                                                                  _offline,
                                                                  "days"
                                                              );
                                                          //Will return th
                                                          if (i <= 100) {
                                                              return (
                                                                  <tr key={i}>
                                                                      <td>
                                                                          {moment(
                                                                              log.offlineTime
                                                                          ).format(
                                                                              "HH:mm:ss - DD/MM/YY"
                                                                          )}
                                                                      </td>
                                                                      <td>
                                                                          {moment(
                                                                              log.onlineTime
                                                                          ).format(
                                                                              "HH:mm:ss - DD/MM/YY"
                                                                          )}
                                                                      </td>

                                                                      <td>
                                                                          {`${differenceInDays}D:${differenceInHours}H:${differenceInMinutes}M:${differenceInSeconds}S`}
                                                                      </td>
                                                                  </tr>
                                                              );
                                                          }
                                                      }
                                                  )}
                                              </tbody>
                                          </table>
                                      </section>
                                  </>
                              ) : null}
                          </CustomTabPanel>
                      );
                  })
                : null}
            <Button
                variant="contained"
                className="close-room-btn"
                color="error"
                onClick={() => setOpenSiteModal(false)}
            >
                Close
            </Button>
        </Box>
    );
}
