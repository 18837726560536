import { axiosInstance } from "./axiosInstance";
const url = process.env.REACT_APP_API_URL;

const getSites = () => {
    return axiosInstance
        .get(`${url}/Event/sites`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return [];
        });
};

const getReasons = () => {
    return axiosInstance
        .get(`${url}/Reasons`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return [];
        });
};

const getDevices = () => {
    return axiosInstance
        .get(`${url}/device`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return [];
        });
};

const getEventTypes = () => {
    return axiosInstance
        .get(`${url}/EventTypes`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return [];
        });
};

export { getSites, getReasons, getDevices, getEventTypes };
