import { useState, useEffect } from "react";
import axios from "axios";
//Components
import Room from "./Room";

export default function ConnectedDevices() {
    const url = process.env.REACT_APP_API_URL;
    const [connectedDevicesData, setConnectedDevicesData] = useState([]);

    useEffect(() => {
        fetchConnectedDevicesData();
    }, []);

    const fetchConnectedDevicesData = async () => {
        try {
            const _connectedDevicesData: any = await axios.get(
                `${url}/room/all`
            );
            setConnectedDevicesData(_connectedDevicesData.data);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <section className="connected-devices-report">
            {connectedDevicesData.length > 0
                ? connectedDevicesData.map((room: any, i: number) => {
                      return <Room key={i} room={room} />;
                  })
                : null}
        </section>
    );
}
