import { useState, useEffect } from "react";
import { axiosInstance } from "../utils/axiosInstance";
import "./styles/historic.scss";
import moment from "moment";
import { Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MultiSelect from "./base/MultiSelect";
import { getSites } from "../utils/getters";
import { useNavigate } from "react-router-dom";
import StatusTag from "./base/Status";

interface Option {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: Option[];
  placeholder?: string;
  onChange: (selected: Option[]) => void;
}
interface FilterInputs {
  type: string;
  priority: string;
  status: any[];
  selectedSites: any[];
}
export default function Historic() {
  const [events, setEvents] = useState<any>([]);
  const [date, setDate] = useState<string>(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(0);
  const [sites, setSites] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [order, setOrder] = useState<string>("acs");
  const [selectedSite, setSelectedSite] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState<string>("num_transactions");
  const [fieldValues, setFieldValues] = useState<string>();
  const [filters, setFilters] = useState<any>({
    type: "",
    priority: "",
    status: [],
    selectedSites: [],
  });
  const offset: number = 10;

  useEffect(() => {
    getSites().then((res) => {
      let options = res?.map((option: any, i: number) => {
        return { value: `${option.name}`, label: option.name };
      });
      setSites([...options, { value: "Head office", label: "Head Office" }]);
    });
  }, []);

  useEffect(() => {
    let axiosData = {
      date,
      offset,
      page,
      filters,
    };

    axiosInstance
      .post(`${process.env.REACT_APP_API_URL}/Event/all`, axiosData)
      .then((res) => {
        setEvents(res.data.events);
        setLastPage(Math.ceil(res.data.totalCount / offset));
      })
      .catch((err) => {
        console.log(err);
      });
      
  }, [date, page, filters]);

  const handlePageNumber = () => {};
  const handlePageSizeChange = () => {};
  const handleOrderChange = () => {};
  const handleOrderByChange = () => {};

  const handleFilter = (e: any) => {
    setFilters((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePrev = () => {
    if (page != 1) {
      setPage(page - 1);
    }
  };
  const handleNext = () => {
    setPage(page + 1);
  };

  const handleResetFilters = () => {
    setFilters({
      type: "",
      priority: "",
      status: [],
      selectedSites: [],
    });
    setPage(1)
  };

  const handleMultiSelectChange = (selected: Option[]) => {
    setFilters((prev: any) => ({ ...prev, selectedSites: selected }));
    setPage(1)
  };
  const handleStatusChange = (selected: Option[]) => {
    setFilters((prev: any) => ({ ...prev, status: selected }));
    setPage(1)
  };

  function dateCheck(date: any): boolean {
    let currentDate = moment(new Date).format("YYYY-MM-DD"); // Get current date
    if (date > currentDate) {
      
      alert("Can't predict future, Please select current or past dates!")
      return false; // Return today's date if future date is passed
    }
    return true;
  }

  const handleSetDate = (e:any)=>{
    
        let isValid = dateCheck(moment(e.target.value).format("YYYY-MM-DD"))
        if(isValid){

            setPage(1);
            setDate(moment(e.target.value).format("YYYY-MM-DD"));
        }
      
  }

  //   const filterTable = (data: any[], filters: FilterInputs) => {

  //       return data.filter((item) => {
  //           const typeMatch = filters.type ? item.typeName.toLowerCase().includes(filters.type.toLowerCase()) : true;
  //           const priorityMatch = filters.priority ? item.priorityName.toLowerCase().includes(filters.priority.toLowerCase()) : true;
  //           const statusMatch = filters.status ? item.statusName.toLowerCase().includes(filters.status.toLowerCase()) : true;
  //           const multiSelectMatch = filters?.selectedSites?.length> 0
  //           ? filters?.selectedSites?.some((option) => item.endLocation.toLowerCase() === option.value.toLowerCase())
  //           : true;
  //         //   console.log('conditoins' ,typeMatch , priorityMatch , statusMatch , multiSelectMatch)
  //         //   console.log('item is here', item.statusName)
  //         //   console.log('item is here',typeMatch && priorityMatch && statusMatch && multiSelectMatch&& item)
  //           return typeMatch && priorityMatch && statusMatch && multiSelectMatch;
  //         });
  //     };
  
  //     console.log('filter is here', filters, '\n\nfiltered table is here', filterTable(events, filters))
  
  const types = [
    { label: "Select Type", value: "" },
    { label: "Face CCTC", value: "face cctv" },
    { label: "Face", value: "face" },
    { label: "Drive Off", value: "drive off" },
    { label: "Car Alert", value: "car alert" },
  ];
  const Priorities = [
    { label: "Select Priority", value: "" },
    { label: "High", value: "high" },
    { label: "Medium", value: "medium" },
    { label: "Low", value: "low" },
  ];

  const statusTypes = [
    { label: "Select Status", value: "" },
    { label: "Created", value: "created" },
    { label: "Failed", value: "failed" },
    { label: "Proccessing", value: "processing" },
    { label: "Successfull", value: "sucessful" },
  ];
  return (
    <main className="historic">
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          columnGap: 20,
          justifyContent: "center",
        //   margin: "20px 50px",
          margin: 'clamp(10px, 3vw, 50px)',
          paddingLeft: 'clamp(10px, 3vw, 0.3rem)',
          marginBottom:'10px', 
          border: "1px solid #ccc",
          borderRadius:'3px'
        }}
      >
        {/* filters */}
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            flexDirection: "row",
            flexWrap: "wrap",

          }}
        >
          {/* <div style={{ flex: 1, textAlign: "right", padding: 10 }}>
            Options
          </div> */}
        
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                // alignItems: "center",
                  marginRight: "20px",
                width: "100%",
                maxWidth: 280,
                position: "relative",

                padding: "0.5rem",
              }}
            >
               <span style={{ flex: 1,  textAlign: "left" , width:'100%',maxWidth: 70 , minWidth:70}}>Date</span>
              <input
                type="date"
                style={{
                  padding: "0.3rem",
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  outline: "none",
                //   width: "100%",
                  height: 27,
                  flex:3
                }}
                value={date}
                onChange={(e) => handleSetDate(e)}
              ></input>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "left",
                // alignItems: "center",
                //   marginLeft: "20px",
                // width: "100%",
                maxWidth: 700,
                position: "relative",

                padding: "0.5rem",
              }}
            >
              <span style={{ flex: 1,  textAlign: "left" , width:'100%',maxWidth: 70, minWidth:70 }}>Site</span>
              
                <MultiSelect
                  options={sites}
                  onChange={handleMultiSelectChange}
                  value={filters?.selectedSites}
                  placeholder="Select Sites"
                />
              
            </div>
       
          {/* <div style={{ flex: 1, textAlign: "right", padding: 10 }}>
            Filters
          </div> */}
      
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
                //   marginLeft: "20px",
                width: "100%",
                maxWidth: 300,
                position: "relative",

                padding: "0.5rem",
                
              }}
            >
              <span style={{ flex: 1, textAlign: "left", width:'100%', maxWidth: 70 , minWidth:70}}>
                Type
              </span>
              <select
                name="type"
                style={{
                //   marginLeft: "0.5rem",
                  padding: "0.3rem",
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  outline: "none",
                  marginRight: "20px",
                  width: "100%",
                  height: "37px",
                  flex:3
                }}
                value={filters?.type}
                onChange={handleFilter}
              >
                {types.map((p) => (
                  <option key={p.value} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
                //   marginLeft: "20px",

                position: "relative",
                width: "100%",
                maxWidth: 300,
                padding: "0.5rem",
              }}
            >
              <span style={{ flex: 1,  textAlign: "left" ,width:'100%', maxWidth: 70, minWidth:70}}>Priority</span>
              <select
                name="priority"
                style={{
                //   marginLeft: "0.5rem",
                  padding: "0.3rem",
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  outline: "none",
                  marginRight: "20px",
                  width: "100%",
                  height: "37px",
                  flex:3
                }}
                value={filters.priority}
                onChange={handleFilter}
              >
                {Priorities.map((p) => (
                  <option key={p.value} value={p.value}>
                    {p.label}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                // alignItems: "center",
                //   marginLeft: "20px",
                width: "100%",
                maxWidth: 700,
                position: "relative",
                padding: "0.5rem",
              }}
            >
              <span style={{ flex: 1,  textAlign: "left" , width:'100%',maxWidth: 70 , minWidth:70}}>Status</span>
              {/* <select
                name='status'
                  style={{
                    marginLeft: "0.5rem",
                    padding: "0.3rem",
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    outline: "none",
                    marginRight: "20px",
                    width:'100%',
                  }}
                  value={filters.status}
                  onChange={handleFilter}
                >
                  {[
                      ["Select Status", ""],
                      ["Created", "created"],
                      ["Failed", "failed"],
                      ["Processing", "processing"],
                      ["Successfull", "sucessful"],
                  ].map((size) => (
                    <option key={size[0]} value={size[1]}>
                      {size[0]}
                    </option>
                  ))}
                </select> */}

              <MultiSelect
                options={statusTypes}
                onChange={handleStatusChange}
                value={filters?.status}
                placeholder="Select Sites"
              />
            </div>
        </div>
      </section>
      <div style={{ display:'flex', flexDirection:'row', justifyContent:'left' , marginLeft: 'clamp(10px, 3vw, 50px)'}}>
        <button style={{ padding: "8px 16px",
                //   margin: "0 8px",
                  border: "none",
                  borderRadius: "3px",
                  background: `linear-gradient(135deg,rgb(71, 155, 252), #007BFF)`,
                  color: "white",
                  fontSize: "14px",
                //   fontWeight: "400",
                  fontStyle:'bold',
                  cursor: "pointer",
                  transition: "transform 0.2s, box-shadow 0.3s",
                  boxShadow: "1px 4px 12px rgba(0, 0, 0, 0.1)",}}
                  
                  onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.05)"
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                  
                  
                  
                  onClick={() => handleResetFilters()}>Reset filters</button>
      </div>
      {/* Table goes here */}
      {events.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: "30px",
          }}
        >
          <table className="historic_table table">
            <thead>
              <tr>
                <th>Event Id</th>
                <th>End Location</th>
                <th>Type</th>
                <th>Priority</th>
                <th>Status</th>
                <th>Created</th>
                <th>Completed</th>
                <th>...</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event: any, i: number) => {
                return (
                  <tr>
                    <td>{event.eventId}</td>
                    <td>
                      {event.endLocation[0].toUpperCase() +
                        event.endLocation.slice(1)}
                    </td>
                    <td>{event.typeName}</td>
                    <td>{event.priorityName}</td>
                    <td>{event.statusName && <StatusTag status={event.statusName}/>}</td>
                    <td>
                      {moment(event.createdAt).format("DD/MM/YY HH:mm:ss")}
                    </td>
                    <td>
                      {event.completedAt
                        ? moment(event.completedAt).format("DD/MM/YY HH:mm:ss")
                        : "Not Completed"}
                    </td>
                    <td>
                      {" "}
                      {event?.uuid && (
                        <a target="blank" href={`/match-face/${event?.uuid}`}>
                          Info...
                        </a>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 20,
                alignItems: "center",
                width: "30%",
                maxWidth: 300,
              }}
            >
              <button
                style={{
                  padding: "8px 16px",
                  margin: "0 8px",
                  border: "none",
                  borderRadius: "3px",
                  background: `${
                    page <= 1
                      ? "#D3D3D3"
                      : "linear-gradient(135deg,rgb(138, 182, 233), #007BFF)"
                  }`,
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                  transition: "transform 0.2s, box-shadow 0.3s",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                }}
                onMouseOver={(e) =>
                  page > 1 && (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
                disabled={!!(page <= 1)}
                onClick={handlePrev}
              >
                &lt;
              </button>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span>
                  {/* <input
                      // type="number"
                      style={{
                        width: "30px",
                        padding: "0.3rem",
                        textAlign: "center",
                        border: "1px solid #ccc",
                        marginLeft: "0.3rem",
                        borderRadius: "3px",
                        outline: "none",
                      }}
                      value={page}
                      onChange={handlePageNumber}
                    ></input> */}
                  {`${page}  of ${lastPage} pages`}
                </span>
              </div>
              <button
                style={{
                  padding: "8px 16px",
                  margin: "0 8px",
                  border: "none",
                  borderRadius: "3px",
                  background: `${
                    page >= lastPage
                      ? "#D3D3D3"
                      : "linear-gradient(135deg,rgb(138, 182, 233), #007BFF)"
                  }`,
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                  transition: "transform 0.2s, box-shadow 0.3s",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                }}
                onMouseOver={(e) =>
                  page < lastPage &&
                  (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
                disabled={page >= lastPage ? true : false}
                onClick={handleNext}
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
      ) : (
        <h1>No Events</h1>
      )}
    </main>
  );
}
