//React
import { useState, useRef, useEffect } from "react";
import { dataUriToBuffer } from "data-uri-to-buffer";
//MUI
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Alert } from "@mui/material";
//Components
import Loader from "./reuseables/Loader";
//Styling
import "./styles/addface.scss";
//Utils
import { axiosInstance } from "../utils/axiosInstance";
import { getReasons, getSites } from "../utils/getters";

export default function AddFace() {
    //Main
    const url = process.env.REACT_APP_API_URL;
    //Refs
    const inputRef: any = useRef();
    //Getters
    const [sites, setSites] = useState<any[]>([]);
    const [reasons, setReasons] = useState<any[]>([]);
    //Face Info
    const [faceRecImage, setFaceRecImage] = useState<any[]>([]);
    const [imageType, setImageType] = useState<string>("");
    const [selectedSite, setSelectedSite] = useState<number>(0);
    const [selectedReason, setSelectedReason] = useState<number>(0);
    //Alert
    const [alert, setAlert] = useState<boolean>(false);
    const [severity, setSeverity] = useState<any>("");
    const [message, setMessage] = useState<string>("");
    const [disable, setDisable] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    //S3 Data
    const [s3Link, setS3Link] = useState<string>("");
    const [hikFaceId, setHikFaceId] = useState<number>(0);
    const [hikName, setHikName] = useState<string>("");
    const [showName, setShowName] = useState<boolean>();

    const [file, setFile] = useState<any>();

    useEffect(() => {
        getSites().then((res) => setSites(res));
        getReasons().then((res) => setReasons(res));
    }, []);

    const handleOpenName = () => setShowName(true);
    const handleCloseName = () => setShowName(false);

    const handleAlert = (message: string, severity: any, duration: number) => {
        setDisable(true);
        setMessage(message);
        setSeverity(setSeverity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, duration);
    };

    //Format image
    const handleImage = (img: any) => {
        setFaceRecImage([]);
        const fileReader = new FileReader();
        fileReader.readAsDataURL(img);

        fileReader.onload = () => {
            setFaceRecImage([...faceRecImage, fileReader.result]);
        };

        let type = img.name.split(".").pop().toLowerCase();
        setImageType(type);
    };

    function base64ToArrayBuffer(base64: any) {
        // var binaryString = atob(base64);
        var bytes = new Uint8Array(base64.length);
        for (var i = 0; i < base64.length; i++) {
            bytes[i] = base64.charCodeAt(i);
        }
        return bytes.buffer;
    }

    //Remove Image and clear input
    const handleClear = () => {
        setSelectedReason(0);
        setSelectedSite(0);
        setFaceRecImage([]);
        inputRef.current.value = "";
        setImageType("");
    };

    const handleValidation = () => {
        //TODO DO A CHECK ON FACE REC IMAGE THAT IT MEETS EXPECTATIONS
        if (selectedSite === 0) {
            handleAlert("Please select a site", "warning", 5000);
        } else if (imageType === "") {
            handleAlert("Please add an image", "warning", 5000);
        } else if (imageType === "") {
        } else if (selectedReason === 0) {
            handleAlert("Please select a Reason", "warning", 5000);
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        setLoader(true);
        let data = {
            siteId: selectedSite,
            reasonId: selectedReason,
            fileType: imageType,
        };

        axiosInstance
            .post(`${url}/Face/add-face`, data)
            .then(async (res) => {
                setS3Link(res.data.url);
                setHikName(res.data.name);
                s3Submit(res.data.url, res.data.hikFaceId, res.data.name);

                handleAlert("Successfully uploaded", "success", 5000);
                handleClear();
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    };

    const s3Submit = async (
        s3uploadlink: string,
        faceId: number,
        name: string
    ) => {
        let buffer = dataUriToBuffer(faceRecImage[0]);
        let uploadedSuccessfully = false;
        for (let i = 0; i < 3; i++) {
            await axiosInstance
                .put(s3uploadlink, buffer.buffer, {
                    headers: { "Content-Type": `image/${imageType}` },
                })
                .then(async (res) => {
                    console.log("ATTEMPT", i);
                    if (res && res.status === 200) {
                        await axiosInstance
                            .post(`${url}/Face/confirm-face`, {
                                hikFaceId: faceId,
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        uploadedSuccessfully = true;
                        handleAlert("Successfully uploaded", "success", 5000);
                        handleOpenName();
                        handleClear();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            if (uploadedSuccessfully) {
                break;
            }
        }
        if (!uploadedSuccessfully) {
            await axiosInstance
                .post(`${url}/Face/confirm-face`, {
                    hikFaceId: faceId,
                })
                .catch((err) => {
                    console.log(err);
                });
            handleAlert("Image has not uploaded to S3", "error", 5000);
            handleClear();
        }
        setLoader(false);
    };

    return (
        <main className="add_face">
            <h1 className="main_heading">Add Face</h1>
            <section className="add_face_inputs">
                <select
                    onChange={(e) => setSelectedSite(parseInt(e.target.value))}
                    value={selectedSite}
                >
                    <option value={0}>Select a Site</option>
                    {sites.length > 0
                        ? sites.map((site: any, i: number) => {
                              return (
                                  <option key={i} value={site.siteId}>
                                      {site.name}
                                  </option>
                              );
                          })
                        : null}
                </select>
                <input
                    className="add_face_upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                            console.log(e.target.files[0]);
                            handleImage(e.target.files[0]);
                        } else {
                            handleClear();
                        }
                    }}
                    ref={inputRef}
                />{" "}
                <select
                    onChange={(e) =>
                        setSelectedReason(parseInt(e.target.value))
                    }
                    value={selectedReason}
                >
                    <option>Select a Reason</option>
                    {reasons.length > 0
                        ? reasons.map((reason: any, i: number) => {
                              return (
                                  <option key={i} value={reason.reasonId}>
                                      {reason.name}
                                  </option>
                              );
                          })
                        : null}
                </select>
            </section>
            <section className="alert_section">
                {alert ? (
                    <Alert className="alert_box" severity={severity}>
                        {message}
                    </Alert>
                ) : null}
            </section>
            <section>
                {showName ? (
                    <div className="hikDetails">
                        <h1>Hik Name: {hikName} </h1>
                        <p onClick={handleCloseName}>X</p>
                    </div>
                ) : null}
            </section>
            <section className="add_face_display">
                {!loader ? (
                    <>
                        {faceRecImage.length > 0 ? (
                            <img
                                src={faceRecImage[0]}
                                className="face_rec_image"
                            />
                        ) : (
                            <NotInterestedIcon className="no_image_placeholder" />
                        )}
                    </>
                ) : (
                    <Loader />
                )}
            </section>
            <section className="add_face_btns">
                <Button
                    variant="contained"
                    className="add_btn"
                    endIcon={<AddCircleIcon />}
                    onClick={handleValidation}
                    disabled={disable}
                >
                    Add
                </Button>
                <Button
                    variant="contained"
                    className="clear_btn"
                    endIcon={<HighlightOffIcon />}
                    onClick={handleClear}
                >
                    Clear
                </Button>
            </section>
        </main>
    );
}
